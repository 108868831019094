<!--
 * @Author: gaojingran
 * @Date: 2021-03-20 13:41:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-18 17:16:59
 * @Description: 404
-->

<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <div class="page-404 full pos-rel">
    <a-result class="pos-center" status="404" title="404" :sub-title="$t('notFound.desc')">
      <template #extra>
        <a-space :size="10">
          <a-button @click="$router.go(-1)">{{ $t('notFound.back') }}</a-button>
          <a-button type="primary" @click="$router.replace('/')">{{ $t('notFound.home') }}</a-button>
        </a-space>
      </template>
    </a-result>
  </div>
</template>

<script>
import { clearLoginInfo } from '@/utils/utils'
export default {
  name: 'Page404',
  mounted() {
    if (this.$route.params.pathMatch && this.$route.params.pathMatch === '/project/schedule') {
      clearLoginInfo()
      // window.location.href = '/login'
      this.$g.returnLogin()
    }
  },
}
</script>
